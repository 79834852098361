exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agents-tsx": () => import("./../../../src/pages/agents.tsx" /* webpackChunkName: "component---src-pages-agents-tsx" */),
  "component---src-pages-arti-tsx": () => import("./../../../src/pages/arti.tsx" /* webpackChunkName: "component---src-pages-arti-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-dataset-tsx": () => import("./../../../src/pages/dataset.tsx" /* webpackChunkName: "component---src-pages-dataset-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-project-tsx": () => import("./../../../src/pages/project.tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */),
  "component---src-pages-userstorycreator-tsx": () => import("./../../../src/pages/userstorycreator.tsx" /* webpackChunkName: "component---src-pages-userstorycreator-tsx" */),
  "component---src-pages-voice-tsx": () => import("./../../../src/pages/voice.tsx" /* webpackChunkName: "component---src-pages-voice-tsx" */)
}

